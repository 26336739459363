* {
  margin: 0;
  padding: 0;
}

.App {
  background: linear-gradient(to right, #16222A, #3A6073);
  padding-bottom: 80vh;
  text-align: center;
}

.container {
  margin-left: 5vw;
  margin-right: 5vw;
}

.list {
  padding-top: 175px;
}

.signOut {
  height: 50px;
  width: 100;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.555);
  font-size: 1.1rem;
  font-weight: 700;
  box-shadow: 0 0 5px black;
  color: red;
}