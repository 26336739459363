.add {
    padding-top: 30px;
    position: fixed;
    z-index: 1;
}

.itemname {
    width: 90vw;
    height: 70px;
    outline: none;
    border: none;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    border-radius: 30px 30px 0 0;
    background: rgba(255, 255, 255, 0.500);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px black;
    font-weight: 900;
    color: teal;
}

.submit {
    width: 90vw;
    height: 50px;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 700;
    color: blue;
    border: none;
    outline: none;
    border-radius: 0 0 30px 30px;
    background: rgba(255, 255, 255, 0.500);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 5px black;
}

.ul {
    list-style: none;
    box-sizing: border-box;
}

.ul li {
    height: 40px;
    width: 90vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 50px 25px 25px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: inset 0 0 5px black;
    color: rgb(0, 255, 179);
    font-weight: 700;
    position: relative;
}
.spanTwo {
    right: 20px;
    top: 25%;
    position: absolute;
    background: none;
    outline: none;
    border: none;
}

.login-form {
    position: relative;
    top: 40vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 200px;
}

.input {
    display: block;
    width: 300px;
    height: 50px;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.555);
    color: rgb(0, 255, 234);
    font-weight: 700;
    font-size: 1.1rem;
    box-shadow: 0 0 5px black;
}

.log {
    height: 50px;
    width: 100px;
    position: absolute;
    right: 0;
    bottom: 0;
    outline: none;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.555);
    font-size: 1.1rem;
    font-weight: 700;
    box-shadow: 0 0 5px black;
    color: rgb(0, 255, 255);
}

.signUp {
    height: 50px;
    width: 100px;
    position: absolute;
    right: 115px;
    bottom: 0;
    outline: none;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.555);
    font-size: 1.1rem;
    font-weight: 700;
    box-shadow: 0 0 5px black;
    color: rgb(0, 255, 255);
}

.userdata {
    color:rgb(0, 255, 179);
    padding: 20px;
}